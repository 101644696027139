var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.code
    ? _c("code-editor", {
        attrs: { filePath: _vm.filePath, code: _vm.code },
        on: { save: _vm.save },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }