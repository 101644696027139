var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-app-bar",
        {
          attrs: {
            fixed: "",
            app: "",
            "extension-height": "36",
            elevation: "4",
          },
          scopedSlots: _vm._u([
            {
              key: "extension",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-caption font-weight-medium" },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-file-document-edit-outline"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.filePath) + " "),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("v-select", {
            staticClass: "mx-2",
            attrs: {
              "hide-details": "",
              outlined: "",
              dense: "",
              items: _vm.themeItems,
              label: "Theme",
            },
            model: {
              value: _vm.cmOptions.theme,
              callback: function ($$v) {
                _vm.$set(_vm.cmOptions, "theme", $$v)
              },
              expression: "cmOptions.theme",
            },
          }),
          _c("v-text-field", {
            staticClass: "mx-2",
            attrs: {
              "hide-details": "",
              outlined: "",
              dense: "",
              type: "number",
              suffix: "pt",
              label: "Font size",
            },
            model: {
              value: _vm.fontSize,
              callback: function ($$v) {
                _vm.fontSize = _vm._n($$v)
              },
              expression: "fontSize",
            },
          }),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { outlined: "", color: "indigo" },
              on: { click: _vm.saveCode },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("mdi-content-save"),
              ]),
              _vm._v("save"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-main",
        [
          _vm.currentCode
            ? _c("codemirror", {
                style: { "--fontSize": _vm.fontSize + "pt" },
                attrs: { options: _vm.cmOptions },
                model: {
                  value: _vm.currentCode,
                  callback: function ($$v) {
                    _vm.currentCode = $$v
                  },
                  expression: "currentCode",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("tutti-snackbar", { ref: "snackbar" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }